<template>
  <CCallout color="info" class="bg-white"> {{name}} </CCallout>
</template>

<script>
export default {
  name: 'DocsCallout',
  props: {
    content: {
      type: String,
      default: undefined,
      required: false,
    },
    href: {
      type: String,
      default: undefined,
      required: false,
    },
    name: {
      type: String,
      default: undefined,
      required: false,
    },
    plural: Boolean,
  },
  setup() {},
}
</script>
