<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle
      placement="bottom-end"
      class="py-0 setting-icon"
      :caret="false"
    >
      <CIcon icon="cil-settings" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader>
      <!-- <CDropdownItem> <CIcon icon="cil-user" /> Profile </CDropdownItem> -->
      <!-- <CDropdownItem> <CIcon icon="cil-settings" /> Settings </CDropdownItem> -->
      <CDropdownItem @click="handleChangePassword">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-xxl"
          viewBox="0 0 512 512"
          role="img"
        >
          <path
            fill="var(--ci-primary-color, currentColor)"
            d="M384,200V144a128,128,0,0,0-256,0v56H88V328c0,92.635,75.364,168,168,168s168-75.365,168-168V200ZM160,144a96,96,0,0,1,192,0v56H160ZM392,328c0,74.99-61.01,136-136,136s-136-61.01-136-136V232H392Z"
            class="ci-primary"
          ></path>
        </svg>
        Change Password
      </CDropdownItem>
      <CDropdownItem @click="handleLogout">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-xxl"
          viewBox="0 0 512 512"
          role="img"
        >
          <polygon
            fill="var(--ci-primary-color, currentColor)"
            points="77.155 272.034 351.75 272.034 351.75 272.033 351.75 240.034 351.75 240.033 77.155 240.033 152.208 164.98 152.208 164.98 152.208 164.979 129.58 142.353 15.899 256.033 15.9 256.034 15.899 256.034 129.58 369.715 152.208 347.088 152.208 347.087 152.208 347.087 77.155 272.034"
            class="ci-primary"
          ></polygon>
          <polygon
            fill="var(--ci-primary-color, currentColor)"
            points="160 16 160 48 464 48 464 464 160 464 160 496 496 496 496 16 160 16"
            class="ci-primary"
          ></polygon>
        </svg>
        Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
//import avatar from '@/assets/image/avatars/2.jpg'
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  name: "AppHeaderDropdownAccnt",
  setup() {
    const store = useStore();
    const router = useRouter();
    function handleLogout() {
      // if(store?.state?.auth?.user?.role == 12){
      //   store.state.auth.user = null
      //   store.dispatch('logout', null)
      //   router.push('/flipship/login')
      // }else{
      //   store.state.auth.user = null
      //   store.dispatch('logout', null)
      //   router.push('/flipship/login')
      // }

      if (process.env.NODE_ENV === "development") {
        // Check if it's local (development environment)
        if (
          store?.state?.auth?.user?.role === 12 ||
          store?.state?.auth?.user?.role == 13
        ) {
          store.state.auth.user = null;
          store.dispatch("logout", null);
          router.push("/flipship/login");
        } else {
          store.state.auth.user = null;
          store.dispatch("logout", null);
          router.push("/login");
        }
      } else if (process.env.NODE_ENV === "production") {
        // Check if it's production
        store.state.auth.user = null;
        store.dispatch("logout", null);
        router.push("/login");
      }
    }
    function handleChangePassword() {
      router.push("/change-password");
    }
    return {
      //avatar: avatar,
      itemsCount: 42,
      handleLogout,
      handleChangePassword,
    };
  },
};
</script>
<style>
.dropdown-item {
  cursor: pointer;
}
.setting-icon .icon{
    width: 1.5rem !important;
    height: 1.5rem !important;
    color: #000;
}
.header.header-sticky {
  padding: 0px;
}
/* .dropdown-menu .bg-light{
    background-color: #5d1f51 !important;
    color: #fff;
} */
.header-toggler svg .ci-primary,
.setting-icon svg .ci-primary {
  fill: #3c4b64;
}
.header .dropdown-menu {
  background: #fff;
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  border: 0px none;
}
.header .dropdown-menu .dropdown-item {
  font-size: 0.8rem;
  padding: 0.45rem 0.5rem;
  font-weight: 500;
}
.header .dropdown-menu .dropdown-item:not(:last-child) {
  border-bottom: 1px dashed #eee;
}
</style>
