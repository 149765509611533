import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./auth";
import fieldHandler from "./fieldHandler";
import city from "./city";
import common from "./common";

export default createStore({
  plugins: [createPersistedState()],
  modules: {
    auth,
    fieldHandler,
    city,
    common,
  },
});
