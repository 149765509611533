const auth = {
  state: {
    user: null,
  },
  actions: {
    login(context, user) {
      context.commit('login', user)
    },
    logout(context, user) {
      context.commit('logout', user)
    },
  },
  mutations: {
    login(state, user) {
      state.user = user
    },
    logout(state, user) {
      state.user = user
    },
  },
}

module.exports = auth
