<template>
  <CFooter>
    <div>
      <span>ANS</span>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }} </span
      >
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank">Powered by</span>
      <a href="https://www.anscommerce.com/" target="_blank">ANS Commerce</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
