import store from '../store'
const permittedNavs = store?.state?.auth?.user?.permittedNavs || ''
export default {
  hasPermission(url){
    if (url) {
      for (let nav of permittedNavs) {
        for (let subnav of nav.subNav) {
            if (subnav.redirect_uri === url) {
              return true
            }
        }
      }
  
     return false
    } else {
      return false
    }
  }
} 