import axios from "axios";
import Config from "@/config";
import JsBarcode from "jsbarcode";
import jsPDF from "jspdf";
import { DOMImplementation, XMLSerializer } from "xmldom";
import moment from "moment";
import master from "@/service/master";

let barcodeLabelDimensions = {
  "1/2": {
    format: [50, 25],
    height: 20,
    width: 1,
    fontSize: 7,
    marginX: 5, //barcode margin
    marginY: 1,
    imageWidth: 40,
    imgHeight: 10,
    textFontSize: 6,
    textWrapLength: 45,
    textMarginLeft: 3,
    textMarginTop: 15,
    mainHeadingMarginTop: 12,
    mainHeadingMarginLeft: 20,
  },
  "2/2": {
    format: [50, 50],
    height: 60,
    width: 1,
    fontSize: 18,
    marginX: 5,
    marginY: 5,
    imageWidth: 40,
    imgHeight: 20,
    textFontSize: 8,
    textWrapLength: 45,
    textMarginLeft: 3,
    textMarginTop: 32,
    mainHeadingMarginTop: 25,
    mainHeadingMarginLeft: 17,
  },
  "4/6": {
    format: [150, 100],
    height: 100,
    width: 2,
    fontSize: 22,
    marginX: 45,
    marginY: 10,
    imageWidth: 60,
    imgHeight: 30,
    textFontSize: 14,
    textWrapLength: 135,
    textMarginLeft: 6,
    textMarginTop: 65,
    mainHeadingMarginTop: 49,
    mainHeadingMarginLeft: 60,
  },
};

export default {
  /**
   * @param {array} addressArr The address array
   * @param {string} type eg: 'BILLING', 'SHIPPING'
   * @return {string} address
   */
  getWarehouseAddress(addressArr, type = "SHIPPING") {
    let address = "";
    if (addressArr) {
      addressArr.filter((item) => {
        //console.log('getWarehouseAddress item: ', item)
        if (item.type == type) {
          let addressLine1 = item.address_line1
            ? item.address_line1 + ", "
            : "";
          let addressLine2 = item.address_line2
            ? item.address_line2 + ", "
            : "";
          let city = item.city?.name ? item?.city?.name + ", " : "";
          let state = item.state?.name ? item.state?.name + ", " : "";
          let pincode = item.pincode ? item?.pincode : "";
          address = addressLine1 + addressLine2 + city + state + pincode;
        }
      });
    }
    console.log("address: ", address);
    return address;
  },

  /**
   * @param {array} vendorDetails The vendor detail object
   * @param {string} type eg: 'BILLING', 'SHIPPING'
   * @return {string} address
   */
  getVendorAddress(vendorDetails, type = "SHIPPING", showType = false) {
    let address = "";
    if (vendorDetails) {
      if (type == "BILLING") {
        let addressLine1 = vendorDetails.billing_address_one
          ? vendorDetails.billing_address_one + ", "
          : "";
        let addressLine2 = vendorDetails.billing_address_two
          ? vendorDetails.billing_address_two + ", "
          : "";
        let city = vendorDetails.billingCity?.name
          ? vendorDetails?.billingCity?.name + ", "
          : "";
        let state = vendorDetails.billingState?.name
          ? vendorDetails.billingState?.name + ", "
          : "";
        let pincode = vendorDetails.billing_pincode
          ? vendorDetails?.billing_pincode
          : "";
        address = addressLine1 + addressLine2 + city + state + pincode;
      }
      if (type == "SHIPPING") {
        let addressLine1 = vendorDetails.shipping_address_one
          ? vendorDetails.shipping_address_one + ", "
          : "";
        let addressLine2 = vendorDetails.shipping_address_two
          ? vendorDetails.shipping_address_two + ", "
          : "";
        let city = vendorDetails.shippingCity?.name
          ? vendorDetails?.shippingCity?.name + ", "
          : "";
        let state = vendorDetails.shippingState?.name
          ? vendorDetails.shippingState?.name + ", "
          : "";
        let pincode = vendorDetails.shipping_pincode
          ? vendorDetails?.shipping_pincode
          : "";
        address = addressLine1 + addressLine2 + city + state + pincode;
      }
      if (showType) {
        address = type + " - " + address;
      }
    }
    console.log("address: ", address);
    return address;
  },

  /**
   * @param {string} fileName Name of the file
   * @return void
   */
  async downloadFile(
    fileName,
    folderPath = Config.IMAGE_PATH + "/",
    token = ""
  ) {
    console.log("folderPath: ", folderPath);
    let fileUrl = folderPath + fileName;
    let label = this.getFilenameAndExtension(fileName)[0];
    let ext = this.getFilenameAndExtension(fileName)[1];

    console.log("downloadFile fileUrl: ", fileUrl);
    await axios({
      url: fileUrl, // File URL Goes Here
      method: "GET",
      responseType: "blob",
      headers: {
        "x-access-token": token,
      },
    })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: this.getMimeTypeOfFile(ext),
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = label;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch(console.error);
  },

  /**
   * @param {string} pathfilename Name of the file with extension
   * @return {array}
   */
  getFilenameAndExtension(pathfilename) {
    var filenameextension = pathfilename.replace(/^.*[\\/]/, "");
    var filename = filenameextension.substring(
      0,
      filenameextension.lastIndexOf(".")
    );
    var ext = filenameextension.split(".").pop();
    return [filename, ext];
  },

  /**
   * @param {string} fileExt Extension of the file
   * @return {string} mimeType
   */
  getMimeTypeOfFile(fileExt) {
    let mimeType = "";
    switch (fileExt) {
      case "pdf":
        mimeType = "application/pdf";
        break;
      case "zip":
        mimeType = "application/zip";
        break;
      case "jpg":
      case "jpeg":
        mimeType = "image/jpeg";
        break;
      case "png":
        mimeType = "image/png";
        break;
      case "gif":
        mimeType = "image/gif";
        break;
      case "svg":
        mimeType = "image/svg+xml";
        break;
      case "webp":
        mimeType = "image/webp";
        break;
      case "csv":
        mimeType = "text/csv";
        break;
      case "xls":
        mimeType = "application/vnd.ms-excel";
        break;
      case "txt":
        mimeType = "text/plain";
        break;
      case "xlsx":
        mimeType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
    }
    return mimeType;
  },

  /**
   * @param {string} barcodeText The string which will render in the barcode
   * @return {string} mimeType
   */
  generateBarcode(barcodeText) {
    const xmlSerializer = new XMLSerializer();
    const document = new DOMImplementation().createDocument(
      "http://www.w3.org/1999/xhtml",
      "html",
      null
    );
    const svgNode = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );

    JsBarcode(svgNode, barcodeText, {
      xmlDocument: document,
      width: 1,
      fontSize: 15,
      marginLeft: 10,
      marginRight: 10,
    });

    let svgText = xmlSerializer.serializeToString(svgNode);
    return svgText;
  },

  async textToBase64Barcode(obj) {
    var canvas = document.createElement("canvas");
    JsBarcode(canvas, obj.text, {
      format: obj.format,
      height: barcodeLabelDimensions[obj.stickerSize].height,
      width: barcodeLabelDimensions[obj.stickerSize].width,
      fontSize: barcodeLabelDimensions[obj.stickerSize].fontSize,
      displayValue: obj.displayValue,
    });
    return canvas;
  },

  //Generate same barcode multiple times
  generateBarcodePDF(stickerSize, quantity, imageUrl, heading, description) {
    let lebelDimensions = barcodeLabelDimensions[stickerSize];

    var imgWidth = lebelDimensions.imageWidth;
    var imgHeight = lebelDimensions.imgHeight;
    var heightLeft = imgHeight;
    var position = lebelDimensions.marginY;

    var doc = new jsPDF("l", "mm", lebelDimensions.format);
    doc.addImage(
      imageUrl,
      "PNG",
      lebelDimensions.marginX,
      position,
      imgWidth,
      imgHeight
    );
    doc.setFont("monospace");
    doc.setFontSize(lebelDimensions.textFontSize);
    var splitTitle = doc.splitTextToSize(
      description,
      lebelDimensions.textWrapLength
    );
    doc.text(
      lebelDimensions.mainHeadingMarginLeft,
      lebelDimensions.mainHeadingMarginTop,
      heading
    );
    doc.text(
      lebelDimensions.textMarginLeft,
      lebelDimensions.textMarginTop,
      splitTitle
    );

    for (let i = 1; i < quantity; i++) {
      position += heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(
        imageUrl,
        "PNG",
        lebelDimensions.marginX,
        position,
        imgWidth,
        imgHeight
      );
      doc.text(
        lebelDimensions.textMarginLeft,
        lebelDimensions.textMarginTop,
        splitTitle
      );
      doc.text(
        lebelDimensions.mainHeadingMarginLeft,
        lebelDimensions.mainHeadingMarginTop,
        heading
      );
    }
    document.querySelector(".spinner").style.display = "none";
    doc.save("labels.pdf");
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  },

  generateBarcodeArrPDF(stickerSize, barcodeArr) {
    let lebelDimensions = barcodeLabelDimensions[stickerSize];
    console.log("barcodeArr: ", barcodeArr);
    var imgWidth = lebelDimensions.imageWidth;
    var imgHeight = lebelDimensions.imgHeight;
    var heightLeft = imgHeight;
    var position = lebelDimensions.marginY;

    var doc = new jsPDF("l", "mm", lebelDimensions.format);
    doc.setFontSize(lebelDimensions.textFontSize);
    doc.setFont("monospace");

    let counter = barcodeArr.length;
    for (let i = 0; i < counter; i++) {
      let imageUrl = barcodeArr[i].barcodeBase64Url;
      let barcodeText = barcodeArr[i].barcodeText;
      let xOffset = doc.internal.pageSize.width / 2;

      position += heightLeft - imgHeight;
      doc.addImage(
        imageUrl,
        "PNG",
        lebelDimensions.marginX,
        position,
        imgWidth,
        imgHeight
      );
      doc.text(barcodeText, xOffset, lebelDimensions.mainHeadingMarginTop, {
        align: "center",
      });

      if (i + 1 < counter) {
        doc.addPage();
      }
    }
    document.querySelector(".spinner").style.display = "none";
    doc.autoPrint();
    window.open(doc.output("bloburl"), "_blank");
  },

  /**
   * @param {string} elementId Div id which we want to print
   * @param {string} printFileName Name of the print file
   * @return void
   */
  printHtmlElement(elementId, printFileName = "print.pdf", printHeader = true) {
    var divToPrint = document.getElementById(elementId);
    // Get all stylesheets HTML
    let stylesHtml = "";
    for (const node of [
      ...document.querySelectorAll('link[rel="stylesheet"], style'),
    ]) {
      stylesHtml += node.outerHTML;
    }

    var newWin = window.open("", "PrintWindow");
    newWin.document.open();
    //https://www.smashingmagazine.com/2011/11/how-to-set-up-a-print-style-sheet/
    let message = divToPrint.innerHTML;
    let html = `<html>
                    <head>
                        ${stylesHtml}
                        <style>
                            @page{
                                size: A4;
                                /* margin: 11mm 17mm 17mm 17mm; */
                            }
                            @page :left {
                                margin: 0.5cm;
                            }
                            @page :right {
                                margin: 0.8cm;
                            }
                            @media print {
                                html, body {
                                    width: 210mm;
                                    height: 297mm;
                                    font: 12pt Georgia, "Times New Roman", Times, serif;
                                    line-height: 1.3;
                                  }
                                page{
                                    display: block;
                                }
                                .printHeaderWrap{
                                    postiction: fixed;
                                    top: 0;
                                    width: 100%;
                                    display: block;
                                    overflow: hidden;
                                }
                                .printMainWrap{
                                    margin: 15mm 0 !important;
                                    page-break-inside: avoid;
                                }
                                .printFooterWrap{
                                    postiction: fixed;
                                    bottom: 0;
                                    border: 1px solid red;
                                    text-align: center;
                                    font-size: 10px;
                                    color: #b6b6b6;
                                }
                            }
                        </style>
                    </head>
                    <body onload="document.title='${printFileName}'; window.print(); window.onafterprint = window.close;" style="margin:0;padding:0;" dir="ltr" bgcolor="#ffffff">
                        <page size="A4">`;
    if (printHeader) {
      html += `<header class="printHeaderWrap">
                                    <div style="width: 49.5%; float: left;">
                                        <img height="40" style="text-align:left;" title="${
                                          Config.COMPANY_DETAILS.NAME
                                        }" src="${
        Config.COMPANY_DETAILS.LOGO
      }" style="border:0">
                                    </div>
                                    <div style="width: 49.5%; float: right; text-align: right;">
                                        ${moment().format(
                                          Config.DEFAULT_DATE_FORMAT
                                        )}
                                    </div>
                                </header>`;
    }
    html += `<div class="printMainWrap">
                                ${message}
                            </div>
                            <!--<footer class="printFooterWrap">
                                ${Config.COMPANY_DETAILS.ADDRESS}
                            </footer>-->
                        </page>
                    </body>
                </html>`;

    newWin.document.write(html);
    newWin.document.close();
    return true;
  },

  printBarcode(...args) {
    const prtHtml = document.getElementById(args["0"]).innerHTML;

    // Get all stylesheets HTML
    let stylesHtml = "";
    for (const node of [
      ...document.querySelectorAll('link[rel="stylesheet"], style'),
    ]) {
      stylesHtml += node.outerHTML;
    }

    // Open the print window
    const WinPrint = window.open("", "PrintWindow");

    WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body onload="document.title='barcode';" style="margin:0;padding:0;" dir="ltr" bgcolor="#ffffff">
        <div class="printMainWrap">
        ${prtHtml}
        </div>
        </body>
      </html>`);

    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  },

  /**
   * @param {string} date Date which is need to format
   * @return moment formated date
   */
  datepickerDateFormat(date) {
    return moment(date).format(Config.DEFAULT_DATE_FORMAT);
  },
  /**
   * @param {string} date Date which is need to format
   * @return moment formated date
   */
  datepickerTimeFormat(date) {
    return moment(date).format(Config.DEFAULT_TIME_FORMAT);
  },
  /**
   * @param {string} date Date which is need to format
   * @return moment formated date
   */
  datepickerDateTimeFormat(date) {
    return moment(date).format(Config.DEFAULT_DATE_TIME_FORMAT);
  },

  async getS3DownloadUrl(key, token) {
    if (token) {
      const response = await master.getS3DownloadUrlFromApi(token, {
        key: key,
      });
      return response?.data || "";
    }

    // let downloadUrl1 = response.data
    /* console.log(
      " getS3DownloadUrlFromApi downloadUrl1 ****** ",
      response.data.accessKeyId
    );
    let AWS_ACCESS_KEY_ID = response.data.accessKeyId;
    let AWS_SECRET_ACCESS_KEY = response.data.secretAccessKey;
    let AWS_DEFAULT_REGION = response.data.region;

    let n = {
      accessKeyId: AWS_ACCESS_KEY_ID,
      secretAccessKey: AWS_SECRET_ACCESS_KEY,
      region: AWS_DEFAULT_REGION,
    };

    if (n?.accessKeyId) {
      const s3 = new AWS.S3(n);
      const downloadUrl = (key) =>
        s3.getSignedUrlPromise("getObject", {
          Bucket: process.env.VUE_APP_S3_BUCKET,
          Key: key,
        });
      let url2 = downloadUrl(key);
      console.log(
        " downloadUrl getS3DownloadUrlFromApi downloadUrl1 ******AAAA url2 ",
        url2
      );

      return downloadUrl(key);
    }*/
  },
};
