//const HOST="http://localhost:8090"

let HOST = "";
let hostname = location.hostname;
if (hostname == "localhost") {
  HOST = "http://localhost:8090";
} else if (hostname == "stagebookings.opsify.in") {
  HOST = "https://api-stagebookings.opsify.in";
} else if (hostname == "wms.opsify.in") {
  HOST = "https://api-wms.opsify.in";
} else if (hostname == "dev-wms.opsify.in") {
  HOST = "https://dev-api-wms.opsify.in";
} else if (hostname == "dev-app.flipship.in") {
  HOST = "https://dev-api.flipship.in";
} else if (hostname == "app.flipship.in") {
  HOST = "https://api.flipship.in";
} else if (hostname == "wms.flipship.in") {
  HOST = "https://api-wms.flipship.in";
}

console.log("hostname: ", hostname);
console.log("API HOST: ", HOST);

//const API_PATH = `${HOST}/api`;
const API_PATH = `${HOST}`;
const IMAGE_PATH = `${HOST}/uploads`;
const PUBLIC_PATH = `${HOST}/public`;
const ADMIN_APPOINTMENT_VIEW_ID = 1;
const VENDOR_APPOINTMENT_VIEW_ID = 2;
const GATE_ENTRY_APPOINTMENT_VIEW_ID = 3;
const UNLOADING_APPOINTMENT_VIEW_ID = 4;
const GRN_APPOINTMENT_VIEW_ID = 5;
const PUTAWAY_APPOINTMENT_VIEW_ID = 6;
const BRAND_APPOINTMENT_VIEW_ID = 7;
const PACKER_APPOINTMENT_VIEW_ID = 8;

const SUPERADMIN_ROLE_ID = 1;
const WAREHOUSE_ADMIN_ROLE_ID = 2;
const VENDOR_ROLE_ID = 3;
const GATE_ENTRY_ROLE_ID = 4;
const UNLOADING_ROLE_ID = 5;
const GRN_ROLE_ID = 6;
const PUTAWAY_ROLE_ID = 7;
const BRAND_ROLE_ID = 9;
const PACKER_ROLE_ID = 11;
const FLIPSHIP_API_ROLE_ID = 12;
const FLIPSHIP_ADMIN_ROLE_ID = 13;

const ENABLE_OPSIFY_2FA = process.env.VUE_APP_ENABLE_OPSIFY_2FA === "true";
const GOOGLE_RECAPTCHA_SITE_KEY = process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY;

//const DEFAULT_DATE_FORMAT = 'DD MMMM YYYY'
const DEFAULT_DATE_FORMAT = "DD MMM YYYY";
const DEFAULT_TIME_FORMAT = "hh:mm A";
const DEFAULT_DATE_TIME_FORMAT =
  DEFAULT_DATE_FORMAT + " " + DEFAULT_TIME_FORMAT;
const MAX_FILE_UPLOAD_SIZE = 30000; //In KB
const MAX_FILE_UPLOAD_SIZE_LABLE = "25MB";
const ALLOWED_FILE_EXTENSIONS = [
  "pdf",
  "zip",
  "jpg",
  "jpeg",
  "png",
  "csv",
  "xls",
  "txt",
];

const GATEENTRY_CHECKLIST = [
  {
    key: "appointmentNumberChecked",
    label: "Appointment Number Checked",
  },
  {
    key: "appointmentDateChecked",
    label: "Appointment Date Checked",
  },
  /* {
    key: 'vendorDetailChecked',
    label: 'Vendor detail checked',
  }, */
  {
    key: "hardCopyOfInvoicePresent",
    label: "Hard copy of Invoice Present",
  },
  {
    key: "WarehouseAddressChecked",
    label: "Warehouse Address Checked",
  },
];

const UNLOADING_CHECKLIST = [
  {
    key: "stackingHeightOfProduct",
    label: "Stack Height less than 6 feet",
  },
  {
    key: "invoiceNumberPresent",
    label: "Vendor Invoice Number Present",
  },
];

const GRN_CHECKLIST = [
  {
    key: "accepted",
    label: "Accept the GRN",
  },
];

const APPOINTMENT_STATUS = {
  CONFIRMED_LABEL: "Confirmed",
  CONFIRMED: 1,
  DOCK_ALLOTED_LABEL: "Dock Alloted",
  DOCK_ALLOTED: 3,
  UNLOADING_IN_PROGRESS_LABEL: "Unloading IN Progress",
  UNLOADING_IN_PROGRESS: 4,
  UNLOADING_HOLD_LABEL: "Hold at Unloading",
  UNLOADING_HOLD: 5,
  UNLOADING_DONE_LABEL: "Unloading Done",
  UNLOADING_DONE: 6,
  STAGING_DONE_LABEL: "Staging Done",
  STAGING_DONE: 7,
  GRN_IN_PROGRESS_LABEL: "GRN In Progress",
  GRN_IN_PROGRESS: 10,
  HOLD_AT_GRN_LABEL: "Hold at GRN",
  HOLD_AT_GRN: 17,
  GRN_DONE_LABEL: "GRN Done",
  GRN_DONE: 11,
  PUTAWAY_IN_PROGRESS_LABEL: "Putaway In Progress",
  PUTAWAY_IN_PROGRESS: 12,
  PUTAWAY_DONE_LABEL: "Putaway Done",
  PUTAWAY_DONE: 13,
  REJECTED_LABEL: "Rejected",
  REJECTED: 16,
  HOLD_AT_GATE_ENTRY_LABEL: "Hold at Gate Entry",
  HOLD_AT_GATE_ENTRY: 18,
};

const FORM_REDIRECT_TIME = 2000;
let STAGIN_AREA_CODE_LIST = [];
new Array(50).fill().forEach((e, i) => {
  let counter = ++i;
  STAGIN_AREA_CODE_LIST.push({
    key: `STAGE-${counter}`,
    label: `Stage Area ${counter}`,
  });
});

const COMPANY_DETAILS = {
  NAME: "ANS COMMERCE",
  LOGO: "https://cdn1.staticans.com/live/ans-project/img/logo.png",
  ADDRESS:
    "The Office Pass, 5th Floor, Tower C, Unitech Cyber Park, Sector 39 Gurugram, Haryana - 122001",
};

const MAX_PAGE_SIZE = 25;

const POD_FILE_TAG = "POD";
const INVOICE_FILE_TAG = "INVOICE";
const INVOICE_STAMP_FILE_TAG = "INVOICE_WITH_STAMP";
const GRN_FILE_TAG = "GRN";
const EXCESS_QTY_FILE_TAG = "EXCESS_QTY_INVOICE";
const BOX_FILE_TAG = "BOX";

const UC_EXPORT_JOB_STATUS = [
  {
    key: 0,
    value: "Pending",
    color: "#f9b115", //yellow
  },
  {
    key: 1,
    value: "Job Created",
    color: "#39f", //blue
  },
  {
    key: 2,
    value: "File Created",
    color: "#321fdb", //purple
  },
  {
    key: 3,
    value: "File Downloaded",
    color: "#2eb85c", //green
  },
  {
    key: 4,
    value: "Save",
    color: "#4f5d73", //gray
  },
];

export default {
  API_PATH: API_PATH,
  IMAGE_PATH: IMAGE_PATH,
  PUBLIC_PATH: PUBLIC_PATH,
  ADMIN_APPOINTMENT_VIEW_ID,
  VENDOR_APPOINTMENT_VIEW_ID,
  GATE_ENTRY_APPOINTMENT_VIEW_ID,
  UNLOADING_APPOINTMENT_VIEW_ID,
  GRN_APPOINTMENT_VIEW_ID,
  PUTAWAY_APPOINTMENT_VIEW_ID,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  SUPERADMIN_ROLE_ID,
  WAREHOUSE_ADMIN_ROLE_ID,
  VENDOR_ROLE_ID,
  GATE_ENTRY_ROLE_ID,
  UNLOADING_ROLE_ID,
  GRN_ROLE_ID,
  PUTAWAY_ROLE_ID,
  GATEENTRY_CHECKLIST,
  UNLOADING_CHECKLIST,
  GRN_CHECKLIST,
  APPOINTMENT_STATUS,
  FORM_REDIRECT_TIME,
  STAGIN_AREA_CODE_LIST,
  MAX_FILE_UPLOAD_SIZE,
  MAX_FILE_UPLOAD_SIZE_LABLE,
  ALLOWED_FILE_EXTENSIONS,
  COMPANY_DETAILS,
  MAX_PAGE_SIZE,
  POD_FILE_TAG,
  INVOICE_FILE_TAG,
  INVOICE_STAMP_FILE_TAG,
  GRN_FILE_TAG,
  EXCESS_QTY_FILE_TAG,
  UC_EXPORT_JOB_STATUS,
  BRAND_APPOINTMENT_VIEW_ID,
  BRAND_ROLE_ID,
  PACKER_APPOINTMENT_VIEW_ID,
  PACKER_ROLE_ID,
  BOX_FILE_TAG,
  FLIPSHIP_API_ROLE_ID,
  FLIPSHIP_ADMIN_ROLE_ID,
  ENABLE_OPSIFY_2FA,
  GOOGLE_RECAPTCHA_SITE_KEY,
};
