<template>
  <div class="not-found">
    <p>No record found.</p>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.not-found {
  text-align: center;
  font-size: 18px;
  color: #5e5e5e;
}

p {
  margin: 0;
}
</style>
