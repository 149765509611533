import { createApp } from "vue";
import App from "./App.vue";
//import './axios.conf'
import axios from "./axios.conf"; // Import your configured Axios instance
import CoreuiVue from "@coreui/vue";
import CIcon from "@coreui/icons-vue";
import { iconsSet as icons } from "@/assets/icons";
import DocsCallout from "@/components/DocsCallout";
import DocsExample from "@/components/DocsExample";
import router from "./router";
import store from "./store";
import "./styles/nprogress.css";
import Vue3Toastify from "vue3-toastify";
import NotFound from "./components/NotFound.vue";
const app = createApp(App);
// Set up the global Axios instance with interceptors
app.config.globalProperties.$http = axios;
app.use(Vue3Toastify, {
  theme: "dark",
  type: "default",
  autoClose: 3000,
});

app.use(store);
app.use(router);
app.use(CoreuiVue);
app.provide("icons", icons);
app.component("CIcon", CIcon);
app.component("DocsCallout", DocsCallout);
app.component("DocsExample", DocsExample);
app.component("NotFound", NotFound);
app.mount("#app");
