<template>
  <router-view :key="$router.fullPath"></router-view>
</template>

<style lang="scss">
@import "styles/style";
@import "styles/flipshipStyle";
</style>
<script>
import auth from "./service/auth";
export default {
  name: "App",
  data() {
    return {
      bypassRouteNames: ["ForgotPassword"],
    };
  },
  watch: {
    $route(to, from) {
      // console.log("Form App.js",this.$router)
      // this.$router.options.history.destroy()
      if (from.path == "/login" && to.path == "/") {
        this.$router.go();
      }
    },
  },
  async mounted() {
    //console.log('this.$store.state.auth', this.$store.state.auth)
    const token = this.$store.state.auth.user
      ? this.$store.state.auth.user.token
      : "";
    //console.log("token: ", this.$store.state)
    if (token) {
      const tokenData = await auth.getUserByToken(token);
      if (tokenData.success === false) {
        this.$store.dispatch("logout", null);
        this.$router.push("/login");
      }
    } /* else {
      if (
        !this.bypassRouteNames.includes(this.$router.currentRoute._value.name)
      ) {
        this.$store.dispatch('logout', null)
        this.$router.push('/login')
      }
    } */
  },
};
</script>
