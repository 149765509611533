import axios from "axios";
const store = require("@/store");
const token = store?.default?.state?.auth?.user?.token;
const userId = store?.default?.state?.auth?.user?.id;
const master = {
  async getMaster(token, data) {
    try {
      const response = await axios.get("/master/getMaster", {
        headers: {
          "x-access-token": token,
        },
        params: data,
      });
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        success: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getFacilities(token, data) {
    try {
      const response = await axios.get("/master/getFacilities", {
        headers: {
          "x-access-token": token,
        },
        params: data,
      });
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        success: false,
        data: "Something gone wrong at backend",
      };
    }
  },
  async getFacilitiesByWarehouseIdAndUserId(token, data) {
    try {
      const response = await axios.get(
        "/master/getFacilitiesByWarehouseIdAndUserId",
        {
          headers: {
            "x-access-token": token,
          },
          params: data,
        }
      );
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        success: false,
        data: "Something gone wrong at backend",
      };
    }
  },
  async getBrandByUserId(data) {
    try {
      const response = await axios.get("/master/getBrandByUserId", {
        headers: {
          "x-access-token": token,
        },
        params: data,
      });
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        success: false,
        data: "Something gone wrong at backend",
      };
    }
  },
  async getFacilityVendors(token, data) {
    try {
      const response = await axios.get("/master/getFacilityVendors", {
        headers: {
          "x-access-token": token,
        },
        params: data,
      });
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        success: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getFacilitiesByUserId() {
    try {
      const response = await axios.get("/master/getFacilitiesByUserId", {
        headers: {
          "x-access-token": token,
        },
        params: {
          user_id: userId,
        },
      });
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        success: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getCities(token, data) {
    try {
      const response = await axios.get("/master/getCityByStateId", {
        headers: {
          "x-access-token": token,
        },
        params: data,
      });
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        success: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getFacilitiesByWarehouseIdAndBrandUserId(token, data) {
    try {
      const response = await axios.get(
        "/master/getFacilitiesByWarehouseIdAndBrandUserId",
        {
          headers: {
            "x-access-token": token,
          },
          params: data,
        }
      );
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        success: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getPOrders(token, data) {
    try {
      const response = await axios.get("/master/getPOrders", {
        headers: {
          "x-access-token": token,
        },
        params: data,
      });
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        success: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getFacilityByBrandId(token, data) {
    try {
      const response = await axios.get("/master/getFacilities", {
        headers: {
          "x-access-token": token,
        },
        params: data,
      });
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        success: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getFacilityByBrandIdByWarehouseId(token, data) {
    try {
      const response = await axios.get(
        "/master/getFacilityByBrandIdByWarehouseId",
        {
          headers: {
            "x-access-token": token,
          },
          params: data,
        }
      );
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        status: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getFacilityIdsByWarehouse(token, data) {
    try {
      const response = await axios.get("/master/getFacilityIdsByWarehouse", {
        headers: {
          "x-access-token": token,
        },
        params: data,
      });
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        status: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getPoBYWarehouseBrandFacility(token, data) {
    try {
      const response = await axios.get(
        "/master/getPoBYWarehouseBrandFacility",
        {
          headers: {
            "x-access-token": token,
          },
          params: data,
        }
      );
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        status: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getBrandByBrandType(token, data) {
    try {
      const response = await axios.get("/master/getBrandByBrandType", {
        headers: {
          "x-access-token": token,
        },
        params: data,
      });
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        success: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getAllBrand(token, data) {
    try {
      const response = await axios.get("/master/getAllBrand", {
        headers: {
          "x-access-token": token,
        },
        params: data,
      });
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        success: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getBrandByLoggedinUserId(token, data) {
    try {
      const response = await axios.get("/master/getBrandByLoggedinUserId", {
        headers: {
          "x-access-token": token,
        },
        params: data,
      });
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        status: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getBoxCodeByBrandByWarehouseId(token, data) {
    try {
      const response = await axios.get(
        "/master/getBoxCodeByBrandByWarehouseId",
        {
          headers: {
            "x-access-token": token,
          },
          params: data,
        }
      );
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        status: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  /*
  async getItemWeightByItemSkuItemCategory(token,data){
    try {
      const response=await axios.get('/master/getItemWeightByItemSkuItemCategory', {
          headers: {
          'x-access-token': token,
          },
          params:data
      })
      return (response.status==200) ? response.data :""
    } catch (error) {
      return {
        status:false,
        data:"Something gone wrong at backend"
      }
    }
  },*/

  async checkFacilityNameAndCodeExist(token, data) {
    try {
      const response = await axios.get(
        "/master/checkFacilityNameAndCodeExist",
        {
          headers: {
            "x-access-token": token,
          },
          params: data,
        }
      );
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        status: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getItemDetailsByTrackingNumber(token, data) {
    try {
      const response = await axios.get(
        "/master/getItemDetailsByTrackingNumber",
        {
          headers: {
            "x-access-token": token,
          },
          params: data,
        }
      );
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        status: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getItemListsByTrackingNumber(token, data) {
    try {
      const response = await axios.get("/master/getItemListsByTrackingNumber", {
        headers: {
          "x-access-token": token,
        },
        params: data,
      });
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        status: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getWarehouseBySource(token, data) {
    try {
      const response = await axios.get("/master/getWarehouseBySource", {
        headers: {
          "x-access-token": token,
        },
        params: data,
      });
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        success: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getFacilityIdsByWarehouseAndService(token, data) {
    try {
      const response = await axios.get(
        "/master/getFacilityIdsByWarehouseAndService",
        {
          headers: {
            "x-access-token": token,
          },
          params: data,
        }
      );
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        status: false,
        data: "Something gone wrong at backend",
      };
    }
  },

  async getS3DownloadUrlFromApi(token, data) {
    try {
      const response = await axios.get("/master/getS3DownloadUrlFromApi", {
        headers: {
          "x-access-token": token,
        },
        params: data,
      });

      console.log("API  getS3DownloadUrlFromApi response ", response);
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        status: false,
        data: "Something gone wrong at backend",
      };
    }
  },
};
export default master;
