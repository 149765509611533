import axios from "axios";

const auth = {
  async getUserByToken(token) {
    try {
      const response = await axios.get("/auth/user", {
        headers: {
          "x-access-token": token,
        },
      });
      return response?.status == 200 ? response?.data : "";
    } catch (error) {
      return error?.response?.data || error?.message;
    }
  },
  async getToken(data) {
    try {
      const response = await axios.post("/auth/getToken", data);
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return error.response.data;
    }
  },
  async forgotPassword(data) {
    try {
      const response = await axios.post("/auth/forgotPassword", data);
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return error.response.data;
    }
  },
  async resetPassword(data) {
    try {
      const response = await axios.post("/auth/resetPassword", data);
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return error.response.data;
    }
  },
  async changePassword(data) {
    try {
      const response = await axios.post("/auth/changePassword", data);
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return error.response.data;
    }
  },
  async authenticateUser(data) {
    try {
      const response = await axios.post("/auth/authenticateUser", data);
      return response;
    } catch (error) {
      console.log("error", error);

      return error?.response;
    }
  },

  async forgotFlipshipPassword(data) {
    try {
      const response = await axios.post("/auth/flipship/forgotPassword", data);
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return error.response.data;
    }
  },
  async resetFlipshipPassword(data) {
    try {
      const response = await axios.post("/auth/flipship/resetPassword", data);
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return error.response.data;
    }
  },

  async verifyRecaptcha(data) {
    try {
      const response = await axios.post("/verify-recaptcha", data);
      return response?.status == 200 ? response?.data : "";
    } catch (error) {
      console.log("error", error);
      return error?.response;
    }
  },
};
export default auth;
