<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid class="justify-content-between">
      <CHeaderToggler
        v-if="showToggler"
        class="ps-1"
        @click="$store.commit('toggleSidebar')"
      >
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <div v-else></div>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <img src="../assets/image/flipship-logo.png" alt="" class="logo" />
      </CHeaderBrand>
      <CHeaderNav>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
      <!-- <CHeaderNav>
        <CLink @click="handleLogout">
            <CIcon icon="cil-lock-locked" /> Logout
        </CLink>
      </CHeaderNav> -->
    </CContainer>
    <!-- <CHeaderDivider /> -->
    <!-- <CContainer fluid>
      <AppBreadcrumb />
    </CContainer> -->
  </CHeader>
</template>

<script>
//import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from "./AppHeaderDropdownAccnt";
import { logo } from "@/assets/brand/logo";

import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "AppHeader",
  props: {
    showToggler: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    //AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    function handleLogout() {
      store.state.auth.user = null;
      store.dispatch("logout", null);
      router.push("/login");
    }
    return {
      logo,
      itemsCount: 42,
      handleLogout,
      globalSearch: "",
      showSuggestions: false,
    };
  },
};
</script>
