const city = {
  state: {
    cities: ''
  },
  actions: {
    setCities(context, data) {
      context.commit('setCities', data)
    },
    getCities(context){
      context.commit('getCities')
    }
  },
  mutations: {
    setCities(state, cities) {
      state.cities = cities
    },
    getCities(state) {
      return state.cities
    }
  },
}

module.exports = city
