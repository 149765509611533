import axios from "axios";

const Wallet = {
  async notifications(token, data) {
    try {
      const response = await axios.get(
        "/flipship/merchant/wallet/notifications",
        {
          headers: {
            "x-access-token": token,
          },
          params: data,
        }
      );
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return error.response.data;
    }
  },
  async transactions(token, data, id) {
    try {
      const response = await axios.get(
        `/flipship/merchant/wallet/transactionHistory${
          id ? "?merchant_id=" + id : ""
        }`,
        {
          headers: {
            "x-access-token": token,
          },
          params: data,
        }
      );
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return error.response.data;
    }
  },
  async recharge(token, data) {
    try {
      const response = await axios.post(
        "/flipship/merchant/wallet/recharge",
        data,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        success: false,
        data:
          error?.response?.data?.message || "Something gone wrong at backend",
      };
    }
  },
  async getBalance(token, id) {
    try {
      const response = await axios.get(
        `/flipship/merchant/wallet/getbalance${id ? "?merchant_id=" + id : ""}`,
        {
          headers: {
            "x-access-token": token,
          },
          // params: data,
        }
      );
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        success: false,
        data:
          error?.response?.data?.message || "Something gone wrong at backend",
      };
    }
  },
  async updateBalance(token, data) {
    try {
      const response = await axios.post(
        "/flipship/merchant/wallet/updateBalance",
        data,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        success: false,
        data:
          error?.response?.data?.message || "Something gone wrong at backend",
      };
    }
  },
  async checkRechargeStatus(token, id) {
    try {
      const response = await axios.get(
        `/flipship/merchant/wallet/rechargeStatus/${id}`,
        {
          headers: {
            "x-access-token": token,
          },
          params: {},
        }
      );
      return response.status == 200 ? response.data : "";
    } catch (error) {
      return {
        success: false,
        data:
          error?.response?.data?.message || "Something gone wrong at backend",
      };
    }
  },
};
export default Wallet;
