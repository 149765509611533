const common = {
  state: {
    walletBalance: null,
  },
  actions: {
    updateWalletBalance(context, user) {
      context.commit("updateWalletBalance", user);
    },
  },
  mutations: {
    updateWalletBalance(state, user) {
      state.walletBalance = user;
    },
  },
};

module.exports = common;
