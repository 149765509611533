<template>
  <CSidebar
    class="flipshipSidebar"
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <div class="logo-box" data-v-07b13bd8="">
        <!-- <img class="logo" src="../assets/image/flipship-logo-square.png" size="md" data-v-07b13bd8="" v-if="roleId === 12">
        <img class="logo" src="/img/logo.60e84a44.png" size="md" data-v-07b13bd8="" v-else> -->
        <img
          class="logo"
          src="../assets/image/flipship-logo-square.png"
          size="md"
          data-v-07b13bd8=""
        />
      </div>
      <div class="name">
        <!-- {{roleId == 12 ? "Flipship" : "ANS Opsify" }} -->
        {{ roleId == 12 ? "Flipship" : "Flipship" }}

        <div class="username">Welcome {{ userName }}!</div>
        <!-- <div>{{userRole}}</div> -->
        <!-- <div>Admin</div> -->
      </div>
    </CSidebarBrand>

    <AppSidebarNav />
    <div
      class="wallet-menu"
      v-if="permission.hasPermission('/flipship/wallet/notification')"
    >
      <a href="/flipship/wallet/notification">
        <i class="fa material-icons-outlined fw">account_balance_wallet</i>
        <h5 class="text-left">
          FlipShip Credits
          <span class="roboto-regular d-block" id="wallet-balance-left-menu"
            >₹ {{ balance }}</span
          >
        </h5>
      </a>
    </div>
    <!-- <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    /> -->
  </CSidebar>
</template>

<script>
import Wallet from "@/service/wallet";
import { computed } from "vue";
import { useStore } from "vuex";
import { AppSidebarNav } from "./AppSidebarNav";
import { logoNegative } from "@/assets/brand/logo-negative";
import { sygnet } from "@/assets/brand/sygnet";
import permission from "@/shared/permission";

export default {
  name: "AppSidebar",
  components: {
    AppSidebarNav,
  },
  data() {
    return {
      userName: "",
      store: "",
      userRole: "",
      roleId: "",
      balance: 0,
      permission,
    };
  },
  methods: {
    async getBalance() {
      try {
        const response = await Wallet.getBalance(this.token);
        if (response.success) {
          this.balance = response?.data?.total_balance || 0;
          this.$store.dispatch("updateWalletBalance", this.balance);
        }
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
      }
    },
  },
  setup() {
    const store = useStore();
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(
        () => store.state.fieldHandler.sidebarUnfoldable
      ),
      sidebarVisible: computed(() => store.state.fieldHandler.sidebarVisible),
    };
  },
  mounted() {
    const store = useStore();
    this.store = store;
    this.userName = store.state.auth.user.name;
    this.userRole = store.state.auth.user.roles.role;
    this.roleId = store.state.auth.user.role;
    this.token = this.$store.state.auth.user.token;
    if (this.roleId == 12) {
      this.getBalance();
    }
  },
};
</script>
