<template>
  <div class="flipshipContainer">
    <AppSidebar />
    <div ref="spinner" class="spinner_parent spinner">
      <CSpinner color="success" />
    </div>
    <div
      class="wrapper d-flex flex-column min-vh-100 bg-light-new"
      ref="loggedInWrapper"
    >
      <AppHeader />
      <div class="body flex-grow-1 px-3">
        <router-view />
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script>
//import { CContainer } from '@coreui/vue'
import AppFooter from "@/components/AppFooter.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppSidebar from "@/components/AppSidebar.vue";
import { useStore } from "vuex";

export default {
  name: "DefaultLayout",
  data() {
    return {
      roleId: "",
    };
  },
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    // CContainer,
  },
  mounted() {
    const store = useStore();
    this.roleId = store.state.auth.user.role;
  },
};
</script>
<style scoped>
.spinner_parent {
  display: none;
  position: fixed;
  width: 100%;
  background: #000000;
  z-index: 99999;
  text-align: center;
  height: 100vh;
  opacity: 0.5;
}
.spinner-border.text-success {
  position: absolute;
  top: 50%;
}
span.danger {
  color: red;
}

.card {
  border-radius: 10px;
  border: 0px;
  box-shadow: 1px 2px 21px rgb(99 33 79 / 12%);
  margin-bottom: 30px;
}
</style>
