const store=require("@/store");
const permittedNavs=store?.default?.state?.auth?.user?.permittedNavs;
let routerNavs=[];
if(permittedNavs){
  permittedNavs.map((navs)=>{
    let navChild=[]
    let navObject={
      component: navs.component,
      name: navs.name,
      to: navs.redirect_uri,
      icon: navs.icon,
      badge: {
        color: (navs.badge_color) ? navs.badge_color:"",
      }
    }
    navs.subNav.map((subnavs)=>{
      if(subnavs.is_sub_nav){
        navChild.push({
          component: "CNavItem",
          name: subnavs.name,
          to: subnavs.redirect_uri,
        })
      }
    })
    navObject.items=navChild
    routerNavs.push(navObject)
  })
}


export default routerNavs;

