const fieldHandler = {
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      //console.log("payload: ", payload)
      state.sidebarVisible = payload.value
    },
  },
  actions: {},
}

module.exports = fieldHandler
