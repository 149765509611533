import { h, resolveComponent } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout";
import WelcomePageLayout from "@/layouts/WelcomePageLayout";
import store from "../store";
import NProgress from "nprogress";
import helpers from "@/shared/helpers";
const permittedNavs = store?.state?.auth?.user?.permittedNavs || "";
console.log("store?.state?.auth?.user", store?.state?.auth?.user?.role);
function checkPermission(to) {
  if (permittedNavs) {
    for (let nav of permittedNavs) {
      for (let subnav of nav.subNav) {
        for (let rMatch of to.matched) {
          // console.log("AAAAAAAAAAAAAAAAA",subnav.redirect_uri ,rMatch.path)
          if (subnav.redirect_uri === rMatch.path) {
            return true;
          }
        }
      }
    }

    return { name: "Login" };
  } else {
    //redirect the user to the login page
    return { name: "Login" };
  }
}
function checkFlipshipPermission(to) {
  if (permittedNavs) {
    for (let nav of permittedNavs) {
      for (let subnav of nav.subNav) {
        for (let rMatch of to.matched) {
          // console.log("AAAAAAAAAAAAAAAAA",subnav.redirect_uri ,rMatch.path)
          if (subnav.redirect_uri === rMatch.path) {
            return true;
          }
        }
      }
    }

    return { name: "Flipship Login" };
  } else {
    //redirect the user to the login page
    return { name: "Flipship Login" };
  }
}

async function downloadFile(filename) {
  let url = await helpers.getS3DownloadUrl(filename, this.token);
  window.open(url, "_blank");
}

function publicRouteAfterLogin() {
  if (store?.state?.auth?.user) {
    return true;
  } else {
    return { name: "Login" };
  }
}

const LoginComponent = () => {
  const hostname = window.location.hostname;
  if (hostname === "app.flipship.in" || hostname === "dev-app.flipship.in") {
    return import("@/views/pages/FlipshipLogin");
  }

  return import("@/views/pages/Login");
};

const routes = [
  {
    path: "/dashboard",
    name: "Home",
    component: DefaultLayout,
    redirect: "/dashboard",
    beforeEnter: [checkPermission],
    children: [
      {
        path: "*",
        name: "Page404",
        component: () => import("@/views/pages/Page404"),
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/views/Dashboard.vue"),
        beforeEnter: [checkPermission],
      },
      {
        path: "/appointments",
        name: "Appointments",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        beforeEnter: [checkPermission],
        redirect: "/appointments",
        children: [
          {
            path: "/appointments",
            name: "Appointment List",
            component: () => import("@/views/appointments/index.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/appointments/generate",
            name: "Generate New Appointment",
            component: () => import("@/views/appointments/generate.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/appointments/generate/:id",
            name: "Edit Appointment",
            component: () => import("@/views/appointments/generate.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/appointments/search/:id",
            name: "Search Appointment",
            component: () => import("@/views/appointments/search.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/appointments/search",
            name: "Search Appointment",
            //component: () => import('@/views/appointments/search.vue'),
            component: () =>
              import("@/views/appointments/autosuggestSearch.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/appointments/in-parking-vehicle",
            name: "In Parking Vehicle",
            component: () =>
              import("@/views/appointments/inParkingVehicle.vue"),
            beforeEnter: [checkPermission],
          },
        ],
      },
      {
        path: "/purchase-orders",
        name: "Purchase Orders",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/purchase-orders",
        children: [
          {
            path: "/purchase-orders",
            name: "Purchase Order",
            component: () => import("@/views/appointments/purchaseOrders.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/sync-purchase-orders",
            name: "Sync Purchase Orders",
            component: () =>
              import("@/views/appointments/syncPurchaseOrders.vue"),
            beforeEnter: [checkPermission],
          },
        ],
      },
      {
        path: "/users",
        name: "Users",
        meta: {
          exact: true,
        },
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        beforeEnter: [checkPermission],
        redirect: "/users",
        children: [
          {
            path: "/users",
            name: "User List",
            component: () => import("@/views/users/index.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/users/add-new",
            name: "Create New User",
            component: () => import("@/views/users/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/users/add-new/:id",
            name: "Edit User",
            component: () => import("@/views/users/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/change-password",
            name: "ChangePassword",
            component: () => import("@/views/pages/ChangePassword"),
            beforeEnter: [checkPermission],
          },
        ],
      },
       {
        path: '/appointment-report',
        name: 'Reports',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        beforeEnter: [checkPermission],
        redirect: '/reports/appointment-report',
        children: [
          {
            path: '/reports/appointment-report',
            name: 'Appointment Report',
            component: () => import('@/views/reports/appointment-report.vue'),
            beforeEnter: [checkPermission],
          },
          {
            path: '/reports/appointment-status-report',
            name: 'Appointment Status Report',
            component: () => import('@/views/reports/appointment-status-report.vue'),
            beforeEnter: [checkPermission],
          },
        ],
      },
      {
        path: "/roles",
        name: "Roles",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/roles",
        children: [
          {
            path: "/roles",
            name: "Role List",
            component: () => import("@/views/roles/index.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/roles/add-new",
            name: "Create New Role",
            component: () => import("@/views/roles/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/roles/add-new/:id",
            name: "Edit Role",
            component: () => import("@/views/roles/add-new.vue"),
            beforeEnter: [checkPermission],
          },
        ],
      },
      // ########################## LBH Managment #########################################
      {
        path: "/lbh/lbh-list",
        name: "LBH Management",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/lbh/lbh-list",
        children: [
          {
            path: "/lbh/lbh-list",
            name: "List LBH Data",
            component: () => import("@/views/lbh/index.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/lbh/add-new/:id",
            name: "Edit LBH Data",
            component: () => import("@/views/lbh/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/lbh/search",
            name: "Add LBH",
            component: () => import("@/views/lbh/orderSearch.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/lbh/lbh-report",
            name: "LBH Rrport",
            component: () => import("@/views/lbh/lbh-report.vue"),
            beforeEnter: [checkPermission],
          },
        ],
      },
      //########################### End LBH Management ####################################
      {
        path: "/barcode",
        name: "Barcode",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/barcode",
        children: [
          {
            path: "/barcode",
            name: "List",
            component: () => import("@/views/barcode/index.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/barcode/generate",
            name: "Create Barcodes",
            component: () => import("@/views/barcode/generate.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/roles/add-new/:id",
            name: "Edit Role",
            component: () => import("@/views/roles/add-new.vue"),
            beforeEnter: [checkPermission],
          },
        ],
      },
      //########################################## Master Navigation Start  #########################################
      {
        path: "/state",
        name: "State",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/state",
        children: [
          {
            path: "/state",
            name: "State List",
            component: () => import("@/views/state/index.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/facility/facility-list",
            name: "Facility List",
            component: () => import("@/views/facility/index.vue"),
            beforeEnter: [checkPermission],
          },

          {
            path: "/state/add-new",
            name: "Create New State",
            component: () => import("@/views/state/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/state/add-new/:id",
            name: "Edit State",
            component: () => import("@/views/state/add-new.vue"),
            beforeEnter: [checkPermission],
          },

          {
            path: "/facility/add-new",
            name: "Create New Facility",
            component: () => import("@/views/facility/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/facility/add-new/:id",
            name: "Edit Facility",
            component: () => import("@/views/facility/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/tenants/tenant-list",
            name: "Tenant List",
            component: () => import("@/views/tenant/index.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/tenants/add-new",
            name: "Create New Tenant",
            component: () => import("@/views/tenant/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/tenants/add-new/:id",
            name: "Edit Tenant",
            component: () => import("@/views/tenant/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/brands/brand-list",
            name: "Brand List",
            component: () => import("@/views/brand/index.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/brands/add-new",
            name: "Create New Brand",
            component: () => import("@/views/brand/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/brands/add-new/:id",
            name: "Edit Brand",
            component: () => import("@/views/brand/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/tenantuser/tenant-user-list",
            name: "Tenant User List",
            component: () => import("@/views/tenantuser/index.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/tenantuser/add-new",
            name: "Create New Tenant User",
            component: () => import("@/views/tenantuser/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/tenantuser/add-new/:id",
            name: "Edit Tenant User",
            component: () => import("@/views/tenantuser/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/inboundsla/list",
            name: "Inbound sla List",
            component: () => import("@/views/inboundsla/index.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/inboundsla/add-new",
            name: "Create New Inbound sla",
            component: () => import("@/views/inboundsla/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/inboundsla/add-new/:id",
            name: "Edit Inbound sla",
            component: () => import("@/views/inboundsla/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/boxcategory/list",
            name: "Box Category List",
            component: () => import("@/views/boxcategory/index.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/boxcategory/add-new",
            name: "Create New Box Category",
            component: () => import("@/views/boxcategory/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/boxcategory/add-new/:id",
            name: "Edit Box Category",
            component: () => import("@/views/boxcategory/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/itemmaster/list",
            name: "Item Master",
            component: () => import("@/views/itemmaster/index.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/box/box-list",
            name: "Box Code List",
            component: () => import("@/views/box/index.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/box/add-new",
            name: "Create New Box Code",
            component: () => import("@/views/box/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/box/add-new/:id",
            name: "Edit Box Code",
            component: () => import("@/views/box/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/box/bulk-add",
            name: "Upload Box CSV",
            component: () => import("@/views/box/bulk-add.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/warehouse/warehouse-list",
            name: "Warehouse List",
            component: () => import("@/views/warehouse/index.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/warehouse/add-new",
            name: "Create New Warehouse",
            component: () => import("@/views/warehouse/add-new.vue"),
            beforeEnter: [checkPermission],
          },
          {
            path: "/warehouse/add-new/:id",
            name: "Edit Warehouse",
            component: () => import("@/views/warehouse/add-new.vue"),
            beforeEnter: [checkPermission],
          },
        ],
      },
      //########################################## Master Navigation End  #########################################

      //########################################## Mapping Navigation Start  #########################################
      {
        path: "/mapping",
        name: "Mapping",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/mapping",
        children: [
          {
            path: "/mapping",
            name: "Mapping List",
            component: () => import("@/views/mapping/index.vue"),
            beforeEnter: [checkPermission],
          },
        ],
      },
      //########################################## Mapping Navigation End  #########################################

      //########################################## Facility Navigation Start  #########################################
      {
        path: "/facility",
        name: "Facility",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/facility",
        children: [
          {
            path: "/facility/facility-list",
            name: "Facility List",
            component: () => import("@/views/facility/index.vue"),
            beforeEnter: [checkPermission],
          },
        ],
      },
      //########################################## Facility Navigation End  #########################################
    ],
  },
  {
    path: "/",
    redirect: "/",
    name: "Welcome",
    component: WelcomePageLayout,
    children: [
      {
        path: "/",
        name: "",
        component: () => import("@/views/index"),
        beforeEnter: [publicRouteAfterLogin],
      },
    ],
  },
  {
    path: "/change-password",
    redirect: "/change-password",
    name: "Change Password",
    component: DefaultLayout,
    children: [
      {
        path: "/change-password",
        name: "ChangePassword",
        component: () => import("@/views/pages/ChangePassword"),
        beforeEnter: [publicRouteAfterLogin],
      },
    ],
  },
  {
    path: "/order-tracking",
    // component: WelcomePageLayout,
    children: [
      {
        path: "",
        name: "OrderTrackingInput",
        component: () => import("@/views/pages/OrderTrackingInput"),
        // beforeEnter: [publicRouteAfterLogin],
      },
      // {
      //   path: "details/:id",
      //   name: "OrderTrackingDetails",
      //   component: () => import("@/views/pages/OrderTrackingDetails"),
      //   props: true,
      //   // beforeEnter: [publicRouteAfterLogin],
      // },
    ],
  },
  {
    path: "/pages",
    redirect: "/pages/404",
    name: "Pages",
    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    children: [
      {
        path: "/:pathMatch(.*)*",
        name: "Page404",
        component: () => import("@/views/pages/Page404"),
      },
      {
        path: "500",
        name: "Page500",
        component: () => import("@/views/pages/Page500"),
      },
      {
        path: "/login",
        name: "Login",
        // component: () => import("@/views/pages/Login"),
        component: LoginComponent,
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: () => import("@/views/pages/ForgotPassword"),
      },
      {
        path: "/download/:filename",
        name: "Download",
        beforeEnter: (to, from, next) => {
          const { filename } = to.params;
          downloadFile(filename);
          next();
        },
      },
      {
        path: "/reset-password/:encToken",
        name: "ResetPassword",
        component: () => import("@/views/pages/ResetPassword"),
      },
      {
        path: "register",
        name: "Register",
        component: () => import("@/views/pages/Register"),
      },
      {
        path: "/flipship/register",
        name: "Flipship Register",
        component: () => import("@/views/pages/FlipshipRegister"),
      },
      {
        path: "/flipship/login",
        name: "Flipship Login",
        component: () => import("@/views/pages/FlipshipLogin"),
      },
      {
        path: "/flipship/forget-password",
        name: "Flipship Forget Password",
        component: () => import("@/views/pages/FlipshipForgetPassword.vue"),
      },
      {
        path: "/flipship/reset-password/:encToken",
        name: "Flipship Reset Password",
        component: () => import("@/views/pages/FlipshipResetPassword.vue"),
      },
    ],
  },

  {
    path: "/flipship",
    name: "Flipship",
    component: DefaultLayout,
    redirect: "/pages/404",
    children: [
      {
        path: "/merchant/list",
        name: "Merchant List",
        component: () => import("@/views/merchant/index.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "/merchant/details/:id",
        name: "Merchant Details",
        component: () => import("@/views/merchant/merchant-details.vue"),
        beforeEnter: [],
      },
      {
        path: "/merchant/add-new",
        name: "Add Merchant",
        component: () => import("@/views/merchant/add-new.vue"),
        beforeEnter: [],
      },
      {
        path: "dashboard",
        name: "Flipship Dashboard",
        component: () => import("@/views/flipship/dashboard.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "admin-dashboard",
        name: "Flipship Admin Dashboard",
        component: () => import("@/views/flipship/admin-dashboard.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "my-profile",
        name: "My Profile",
        component: () => import("@/views/flipship/my-profile.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "api-credentials",
        name: "Api Credentials",
        component: () => import("@/views/flipship/api-credentials.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "courier/list",
        name: "Courier List",
        component: () => import("@/views/courier/index.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "courier/add-new",
        name: "Add Courier",
        component: () => import("@/views/courier/add-new.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "courier/add-new/:id",
        name: "Edit Courier",
        component: () => import("@/views/courier/add-new.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "courier-settings",
        name: "Courier Settings",
        component: () => import("@/views/flipship/courier-settings.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "orders",
        name: "List Orders",
        component: () => import("@/views/orders/index.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "order/add-new",
        name: "Add Order",
        component: () => import("@/views/orders/add-new.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "order/add-new/:id",
        name: "Edit Order",
        component: () => import("@/views/orders/add-new.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "order/details/:id",
        name: "Order Details",
        component: () => import("@/views/orders/details.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "status/list",
        name: "Status List",
        component: () => import("@/views/status/index.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "status/add-new",
        name: "Add Status",
        component: () => import("@/views/status/add-new.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "status/add-new/:id",
        name: "Edit Status",
        component: () => import("@/views/status/add-new.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "rate/list",
        name: "Rate List",
        component: () => import("@/views/flipship-rate/index.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "rate/add-new",
        name: "Add Rate",
        component: () => import("@/views/flipship-rate/add-new.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "rate/add-new/:id",
        name: "Edit Rate",
        component: () => import("@/views/flipship-rate/add-new.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "pincode/list",
        name: "Pincode List",
        component: () => import("@/views/flipship-pincode/index.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "pincode/add-new",
        name: "Add Pincode",
        component: () => import("@/views/flipship-pincode/add-new.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "pincode/add-new/:id",
        name: "Edit Pincode",
        component: () => import("@/views/flipship-pincode/add-new.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "wallet/notification",
        name: "Wallet Notification",
        component: () => import("@/views/flipship-wallet/notification.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "wallet/expenses",
        name: "Wallet Expenses",
        component: () => import("@/views/flipship-wallet/expenses.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "wallet/thankyou",
        name: "Wallet Thank You",
        component: () => import("@/views/flipship-wallet/thankyou.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "default-zones/list",
        name: "Default Zones",
        component: () =>
          import("@/views/flipship-zones/default-zones/index.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "default-zones/add-new",
        name: "Add Default Zones",
        component: () =>
          import("@/views/flipship-zones/default-zones/add-new.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "default-zones/add-new/:id",
        name: "Edit Default Zones",
        component: () =>
          import("@/views/flipship-zones/default-zones/add-new.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "merchant-zones/list",
        name: "Merchant Zones",
        component: () =>
          import("@/views/flipship-zones/merchant-zones/index.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "merchant-zones/add-new",
        name: "Add Merchant Zones",
        component: () =>
          import("@/views/flipship-zones/merchant-zones/add-new.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "merchant-zones/add-new/:id",
        name: "Edit Merchant Zones",
        component: () =>
          import("@/views/flipship-zones/merchant-zones/add-new.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "courier/allocation-rule",
        name: "Courier Allocation Rule",
        component: () => import("@/views/courier/allocation-rule/settings.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "courier/allocation-rule-list",
        name: "Courier Allocation Rule List",
        component: () => import("@/views/courier/allocation-rule/index.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "courier/allocation-rule/add-new",
        name: "Add Courier Allocation Rule",
        component: () => import("@/views/courier/allocation-rule/add-new.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "courier/allocation-rule/add-new/:id",
        name: "Edit Courier Allocation Rule",
        component: () => import("@/views/courier/allocation-rule/add-new.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "/merchant/wallet",
        name: "Merchant Wallet",
        component: () => import("@/views/merchant/wallet.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "order/track/:id",
        name: "Track Order",
        component: () => import("@/views/orders/track.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "logs/list",
        name: "Flipship Logs List",
        component: () => import("@/views/flipship-logs/index.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
      {
        path: "logs/details/:id",
        name: "Flipship Logs Details",
        component: () => import("@/views/flipship-logs/details.vue"),
        beforeEnter: [checkFlipshipPermission],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  console.log("beforeEach loading start...");
  //NProgress.configure({ parent: '#container' });
  NProgress.start();
  NProgress.set(0.1);
  next();
});
router.afterEach(() => {
  setTimeout(() => {
    console.log("afterEach loading end...");
    NProgress.done();
  }, 500);
});

export default router;
